import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const Loader = () => {
  return (
    <div className="bg-light d-flex align-items-center justify-content-center vh-100 vw-100">
      <Spinner animation="border" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};
