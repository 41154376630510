import React from "react";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import DellLogo from "images/dell_logo.png";
import "./Navbar.scss";

export const Navbar = () => {
  return (
    <BootstrapNavbar
      bg="light"
      className="Navbar-navbar"
      expand={true}
      sticky="top"
    >
      <BootstrapNavbar.Brand>
        <img alt="Dell logo" height={25} src={DellLogo} width={195} />
      </BootstrapNavbar.Brand>

      <Nav className="ml-auto Navbar-link">
        <Nav.Link
          className="text-dark"
          href="mailto:questions@dellreturncenter.com?subject= Question from Dell ReturnCenter"
        >
          Contact us
        </Nav.Link>
      </Nav>
    </BootstrapNavbar>
  );
};
