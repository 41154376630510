import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { persistor, store } from "./redux/store";
import { Router } from "./components/Router";
import "./App.scss";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={true} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  );
};

export default App;
