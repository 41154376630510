import { Order } from "types";

export const OrderAdapter = (order: Order) => {
  const { assets, equipment } = order;

  const products = [];
  if (equipment.laptop > 0) {
    products.push({ id: 6, quantity: equipment.laptop });
  }

  if (equipment.large > 0) {
    products.push({ id: 4, quantity: equipment.large });
  }

  if (equipment.xLarge > 0) {
    products.push({ id: 35, quantity: equipment.xLarge });
  }

  if (equipment.returnLabels > 0) {
    products.push({ id: 89, quantity: equipment.returnLabels });
  }

  const job_assets = [];
  if (assets.laptop > 0) {
    job_assets.push({ id: 50699, quantity: assets.laptop });
  }

  if (assets.desktop > 0) {
    job_assets.push({ id: 50700, quantity: assets.desktop });
  }

  if (assets.monitor > 0) {
    job_assets.push({ id: 50701, quantity: assets.monitor });
  }

  if (assets.printer > 0) {
    job_assets.push({ id: 50703, quantity: assets.printer });
  }

  if (assets.dockingStation > 0) {
    job_assets.push({ id: 2494, quantity: assets.dockingStation });
  }

  return {
    reference1: "99132011",
    reference2: order.location.serviceNow,
    reference3: order.location.costCenter,
    customer_number: "4348021",
    paradigm_id: 113,
    remarketer_id: 759478,
    job_type: "BEACON HEALTH BOX KITS WITH LABELS",
    manager_email: order.location.managerEmail,
    initiator: {
      first_name: order.user.firstName,
      last_name: order.user.lastName,
      email: order.user.email,
    },
    location: {
      company: order.location.name,
      address: {
        address1: order.location.address1,
        address2: order.location.address2,
        city: order.location.city,
        state: order.location.state,
        zip: order.location.zip,
        country: "US",
      },
      phone: order.location.phone,
      contact: {
        email: order.user.email,
        first_name: order.location.firstName,
        last_name: order.location.lastName,
        phone: order.location.phone,
      },
    },
    destination: {
      id: order.returnAddress.destination_id,
      name: order.returnAddress.name,
      address: {
        address1: order.returnAddress.address1,
        address2: order.returnAddress.address2,
        city: order.returnAddress.city,
        state: order.returnAddress.state,
        zip: order.returnAddress.zip,
        country: "US",
      },
      contact: {
        first_name: order.returnAddress.contact_first_name,
        last_name: order.returnAddress.contact_last_name,
        email: order.returnAddress.contact_email,
        phone: order.returnAddress.contact_phone,
      },
    },
    products,
    assets: job_assets,
  };
};
