import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Assets, Equipment, Location, ReturnAddress, Order, User } from "types";
import { actions as errorActions } from "../error";
import * as jobAPI from "api/job";
import { RootState } from "../store";

// Selectors
export const selectors = {
  getAssets: (state: RootState) => state.order.assets,
  getEquipment: (state: RootState) => state.order.equipment,
  getLocation: (state: RootState) => state.order.location,
  getOrder: (state: RootState) => state.order,
  getReturnAddress: (state: RootState) => state.order.returnAddress,
  getUser: (state: RootState) => state.order.user,
};

// Slice
export const initialState: Order = {
  assets: {
    desktop: 0,
    dockingStation: 0,
    laptop: 0,
    monitor: 0,
    printer: 0,
  },
  equipment: {
    laptop: 0,
    large: 0,
    returnLabels: 0,
    xLarge: 0,
  },
  job_id: "",
  location: {
    address1: "",
    address2: "",
    city: "",
    costCenter: "",
    firstName: "",
    lastName: "",
    managerEmail: "",
    name: "Beacon Health Options",
    phone: "",
    serviceNow: "",
    state: "",
    zip: "",
  },
  returnAddress: {
    address1: "",
    address2: "",
    city: "",
    name: "",
    contact_email: "",
    contact_first_name: "",
    contact_last_name: "",
    contact_phone: "",
    destination_id: "",
    state: "",
    zip: "",
  },
  user: {
    email: "",
    firstName: "",
    lastName: "",
  },
};

export const addJob = createAsyncThunk(
  "order/addJob",
  async (order: Order, { dispatch }) => {
    try {
      return await jobAPI.addJob(order);
    } catch (error) {
      const message = error instanceof Error ? error.message : String(error)
      dispatch(errorActions.setError(message));
      throw error;
    }
  }
);

export const { actions, reducer } = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetOrder: (state: Order, action: PayloadAction<void>) => {
      return initialState;
    },
    setAssets: (state: Order, action: PayloadAction<Assets>) => {
      return {
        ...state,
        assets: action.payload,
      };
    },
    setEquipment: (state: Order, action: PayloadAction<Equipment>) => {
      return {
        ...state,
        equipment: action.payload,
      };
    },
    setLocation: (state: Order, action: PayloadAction<Location>) => {
      return {
        ...state,
        location: action.payload,
      };
    },
    setReturnAddress: (state: Order, action: PayloadAction<ReturnAddress>) => {
      return {
        ...state,
        returnAddress: action.payload,
      };
    },
    setUser: (state: Order, action: PayloadAction<User>) => {
      return {
        ...state,
        user: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addJob.fulfilled, (state: Order, action) => {
      return {
        ...state,
        job_id: action.payload,
      };
    });
  },
});
